import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker Cloud Adapter",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Cloud_Adapter/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker Cloud Adapter' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/IOBroker_Setup/Cloud_Adapter/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Cloud_Adapter/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-cloud-adapter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-cloud-adapter",
        "aria-label": "iobroker cloud adapter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Cloud Adapter`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#iobroker-cloud-adapter"
        }}>{`ioBroker Cloud Adapter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#amazon-alexa"
        }}>{`Amazon Alexa`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ifttt"
        }}>{`IFTTT`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "iobroker-cloud-adapter-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-1",
        "aria-label": "iobroker cloud adapter 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Cloud Adapter`}</h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-2",
        "aria-label": "iobroker cloud adapter 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.43478260869565%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB4ElEQVQY00XN30tTURwA8Psn9A+ELwZZEP5KKwiCUSiEYkhaLVpRPfQLi56KHvIhiEiIejTQWkrZ1u6D0A+WuS03NVSc13m3e87Ouffc3XO+99euI4MKFkIUfN4/Uld3d19vz5lTg6cH+k/29Rw+2Lm/ZV9HW0t763+dbc3/dLQ2725sbGpo2HWoXQp1HR88f+Xh09HReDI6o7xM5qOflbfpdTmjyF+VRGbbZFp9NatO/FW4dH8sfGuk994jaU9o4H1OGRmTj955c3l6KxKvXpBrdxN4IqVOZXE8h2JZfD1hh6eqkVj1XDy4mAhufNi6Nv3jxOuStGNnU+T2g/6bj4eevJPzwfOsF1v2x+e96IIjr7iGE9ASLb+YNNJZc2mVrG5gy0WmwyreLCHS3gNHjp29GgoPDT8bF44vbMfz/GCzBratIQzCcgQPeMXnFZeb3KB+UA0w/l7S1lwuzc2s5NLKfKZQXC4WVIYxJoSAEKbJFGWdEEIo1RkD2xECGDOFAG5ZToUvGGUp+WktNYc/pkh+qawvftHUEkJY0zSkaapapJQihBhjAMA5p4QKwTkAWLBoEIlxa7MW1Ov1n79/mZiWETYMw/M8y7IKhQ2d6oQQXddd1wXYngFAADjC/sbIH2MAVzy8muW0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/93a626fa9b0f52ab283b0c171af33396/e4706/IO_Broker_Cloud_01.avif 230w", "/en/static/93a626fa9b0f52ab283b0c171af33396/d1af7/IO_Broker_Cloud_01.avif 460w", "/en/static/93a626fa9b0f52ab283b0c171af33396/b6582/IO_Broker_Cloud_01.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/93a626fa9b0f52ab283b0c171af33396/a0b58/IO_Broker_Cloud_01.webp 230w", "/en/static/93a626fa9b0f52ab283b0c171af33396/bc10c/IO_Broker_Cloud_01.webp 460w", "/en/static/93a626fa9b0f52ab283b0c171af33396/87ca7/IO_Broker_Cloud_01.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/93a626fa9b0f52ab283b0c171af33396/81c8e/IO_Broker_Cloud_01.png 230w", "/en/static/93a626fa9b0f52ab283b0c171af33396/08a84/IO_Broker_Cloud_01.png 460w", "/en/static/93a626fa9b0f52ab283b0c171af33396/e9beb/IO_Broker_Cloud_01.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/93a626fa9b0f52ab283b0c171af33396/e9beb/IO_Broker_Cloud_01.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-3",
        "aria-label": "iobroker cloud adapter 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "35.65217391304348%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABpklEQVQoz3XMv2tTYRTG8ftPuDu5OYgO/RNcFB38DYKF0HapggURdHGwi5Pg4OAgGKHUYhpjBUkHFSuk9zbxxiatudH0JjHNfc+bNzG5bUyKX0kKKQoe+HAeDofHOnbyCi+SKe4/nufUvZfMvA25nmhwY6nF7GuP2MoGiVSRRMob7ltLQiRmmIobJhcN0wnDzJsm04kGV6MlrENHTnD7wRPG7zzi4mycqViHa3OaiQVDZF4zuaAZn9NcigqXo8L5Z5pz/7gQ1Zx5qrkZF6zDR8cYOxvh+OkJ7j6MokJwq1081R/K1Xps1nsUVJ/sj19sbA9yjy+De3Dwk6/38SXEyrou/lYJXa9hpM7zlS55v013p0mn3SLs/MdPM7LbNgRiyPsNrOVCSPJrSLL0G7e6Q9qtslWsoCtlAv+AKlf+FiiUCEoJIsJ2IJRrgrX4WZNYb/HK2yP1qUDz/TLasdG2Q8NZG5FVe5+zhn73AVnPIcaglBqSoQDLNIT+bshg9oxm8+Mqnp3hm52h6GTxnCxFO81328ZPZ6i4WSSXR/k+9SAYFo1KRfgDHibdFULbEjEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/166cd5f583807a0edc596629713ff9f3/e4706/IO_Broker_Cloud_02.avif 230w", "/en/static/166cd5f583807a0edc596629713ff9f3/d1af7/IO_Broker_Cloud_02.avif 460w", "/en/static/166cd5f583807a0edc596629713ff9f3/b6582/IO_Broker_Cloud_02.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/166cd5f583807a0edc596629713ff9f3/a0b58/IO_Broker_Cloud_02.webp 230w", "/en/static/166cd5f583807a0edc596629713ff9f3/bc10c/IO_Broker_Cloud_02.webp 460w", "/en/static/166cd5f583807a0edc596629713ff9f3/87ca7/IO_Broker_Cloud_02.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/166cd5f583807a0edc596629713ff9f3/81c8e/IO_Broker_Cloud_02.png 230w", "/en/static/166cd5f583807a0edc596629713ff9f3/08a84/IO_Broker_Cloud_02.png 460w", "/en/static/166cd5f583807a0edc596629713ff9f3/e9beb/IO_Broker_Cloud_02.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/166cd5f583807a0edc596629713ff9f3/e9beb/IO_Broker_Cloud_02.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-4",
        "aria-label": "iobroker cloud adapter 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.608695652173914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnklEQVQoz2PIyspatnz5pUuXjh8/fuLEydPnzl+4dBk3unLh4oX6pv7CooqCgnyGHz++v3r74e7zd0/ffX714cv//3/xoB8/fz59+/nd5y/fv3/79u0bw////4/de1O77XrzrpuNO25MPvJg6lEIeoiMJh95MPnwg96D96q33zz24P3//////vsH0rzk3FOF1n06PQe1uw4qte2Tator3bRHqhGBJBv3KLXu0+jYr9V1QK55z+wTD//////nL1jzygvPDHoP2Uw5ZjrhSNjCM127r7XvutZ38G7fgTt9B+70Hrgz6dDdyMVnzfqP2Ew5ptdzaPGZxyiadXsOWk8+qt97KGftxRtPXl95+u7my0+3XoHQjRcfH779XLLxil7PIevJR3W6Dy7C1Gw16ahJ/2G/OSerNl+u33GjftvVsk2XK7dcrdl+vWHnzcD5p437D1vh0mw56ajFxCPG/Ye1uw/qdh9ymbDLqXuLRfdOrU5QWBj1HbaYeMRyEm7N5hOPWEw8YjX5qMmEIzlrLvTuvpq58rzJBJCIxcQj5hiaAUoMgKgGuuIgAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/106d6163fff301f318280b037334d3b1/e4706/IO_Broker_Cloud_03.avif 230w", "/en/static/106d6163fff301f318280b037334d3b1/d1af7/IO_Broker_Cloud_03.avif 460w", "/en/static/106d6163fff301f318280b037334d3b1/b6582/IO_Broker_Cloud_03.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/106d6163fff301f318280b037334d3b1/a0b58/IO_Broker_Cloud_03.webp 230w", "/en/static/106d6163fff301f318280b037334d3b1/bc10c/IO_Broker_Cloud_03.webp 460w", "/en/static/106d6163fff301f318280b037334d3b1/87ca7/IO_Broker_Cloud_03.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/106d6163fff301f318280b037334d3b1/81c8e/IO_Broker_Cloud_03.png 230w", "/en/static/106d6163fff301f318280b037334d3b1/08a84/IO_Broker_Cloud_03.png 460w", "/en/static/106d6163fff301f318280b037334d3b1/e9beb/IO_Broker_Cloud_03.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/106d6163fff301f318280b037334d3b1/e9beb/IO_Broker_Cloud_03.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-5",
        "aria-label": "iobroker cloud adapter 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.34782608695652%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABm0lEQVQoz5WR3UuaARTG/X/mtK/lW42I7oLaFiwKuu9q0MUYxO6CrvKzbA4q2oykiDWpFttYWO+rZtO0D9HWXGkmNpeFVmK0wS98zfBiNx144MDz8OM5HEXkd4bjbJ746SUX+WvuO1d//xE/zZHM5DnLXaMYFfeY8cV4597nQ+CIlcgJjkha1sqvMv38w5dols+xc3kv+UvhFPadY+a2k/xIXaAQtMs0Ghw0vVnjsdlNjV6kUi9RaZB4qBNRakVUg6sozd953mej++UEyhEvVbe5aoNErcnJg8FVZreSKOY3D/noj2IPxPAnsrxaCFGhFxGGXDyd8NE56afDuoFa+5X5+id41Y0oBxYRTJKc0Qy5qBt2odaJReCCf5/lUIKNRIa9dI6+T2FUuiKwybJG81sPLaPrtL33YuzVMt7TT8uYh4Zhl9ysBFSVgM5ICk/0BF/8jHDqnNdLu7JZCD0yOakySNSb3XRNbfJsOkirLUi7NUCD2S37BaBQDrQ4dik8ZuRbCNv6AS/mgncna8oktzFKCEYJzS2o9j/AG525pfoP0k3QAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/b32a6057cec97646ea039d8597d5a238/e4706/IO_Broker_Cloud_04.avif 230w", "/en/static/b32a6057cec97646ea039d8597d5a238/d1af7/IO_Broker_Cloud_04.avif 460w", "/en/static/b32a6057cec97646ea039d8597d5a238/b6582/IO_Broker_Cloud_04.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/b32a6057cec97646ea039d8597d5a238/a0b58/IO_Broker_Cloud_04.webp 230w", "/en/static/b32a6057cec97646ea039d8597d5a238/bc10c/IO_Broker_Cloud_04.webp 460w", "/en/static/b32a6057cec97646ea039d8597d5a238/87ca7/IO_Broker_Cloud_04.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/b32a6057cec97646ea039d8597d5a238/81c8e/IO_Broker_Cloud_04.png 230w", "/en/static/b32a6057cec97646ea039d8597d5a238/08a84/IO_Broker_Cloud_04.png 460w", "/en/static/b32a6057cec97646ea039d8597d5a238/e9beb/IO_Broker_Cloud_04.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/b32a6057cec97646ea039d8597d5a238/e9beb/IO_Broker_Cloud_04.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-6",
        "aria-label": "iobroker cloud adapter 6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.47826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACMElEQVQoz52R20vTARTH94cIRXjJNtE05za1nqSnIlBC0odM0x7KB5EgMYtiOi/hfiLlpsYUvJAOjWoZjKm/zcsuzc35kJuu6XQr1gpEo54+4c8EH3rqwIcD53s4V9nyZoKNxD6eSJLk/m/+x2I/frKdPODL3i9kvkiCte1vOENxgrvfsa5/xeSOMurdYdgTZdh9xIg3xqgvzuhKXPIjH3eknCF3lP7lLfoWI0wF4sjG3RHGXJ8ZcYYxuzapMDlJbbWh6JhF2T2PRhDRCHaytO9Jb5nm7KPXZLRMI9dZkbfbULTbyO6cJaPNxs0xH7LMxxaU7Vbyux3kGrzkvvCg6V1C3eeldHydSnOQ8skQl3rmydO+QamzkN9moci4QvHAKkX9fooH/OT1LFE3sYrMKAbpt4cYXNjA+nycpvt9KHQfyKhpI+XaPVJKGzhd1sCFqibUNQ9R3W5GWd1Menkjp8qOtDPXG8l8MEbtKz+yd94wc59iuLaS7L61ontiIrN1Bnl9L2lVT0mrbiWrToemvpPCE2Tf0ZF6S0tatVZqfq554qjgTCCKYzOBJ7bH2gHcnQmj6LJTZPCiMfrRGH2UmALcmAxSaQ5JJ6iYDHJ5aA21wUeh0SetfV5YpPZw5WlPGItvC7MzxMJ6jIapAPKOOVSCKD3lEJVeRC2IqPV/EUQpdqwX6EVyuuapnfAjKzEsc/WlmyuDh7i42LtAvt5OgeBAecL/i2NNJTjIeSZKE/4B7JX/M0hIwH8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/a81e40e1ce0741c1c0da21af0656cf4d/e4706/IO_Broker_Cloud_05.avif 230w", "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/d1af7/IO_Broker_Cloud_05.avif 460w", "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/b6582/IO_Broker_Cloud_05.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/a81e40e1ce0741c1c0da21af0656cf4d/a0b58/IO_Broker_Cloud_05.webp 230w", "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/bc10c/IO_Broker_Cloud_05.webp 460w", "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/87ca7/IO_Broker_Cloud_05.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/a81e40e1ce0741c1c0da21af0656cf4d/81c8e/IO_Broker_Cloud_05.png 230w", "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/08a84/IO_Broker_Cloud_05.png 460w", "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/e9beb/IO_Broker_Cloud_05.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/a81e40e1ce0741c1c0da21af0656cf4d/e9beb/IO_Broker_Cloud_05.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-7",
        "aria-label": "iobroker cloud adapter 7 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.173913043478265%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABV0lEQVQY03XBvUrDQAAA4DxHX0BwUgTxEQQRNxcFEZVawc1BENTNRQRHfxAHsVprrVQQ6tLaFIIiqf0jIWmvySWX3OVyTZq0tSIoCI5+Hzc6OZfICnvHN1M7yfXHbjTNYhl/6x5c5uWk0EgUG3eCuvHgzN96i6n2Qqq9nG6vZdqrGX/2TOEiwxPbh+cru0exg/Sp0MnJTATOq2zWNSK1qKRTCdKyxl5UwteMQlXna7BYN58rGl+FXGRobHxmaWQ6url/grBNHfYRBv1+dzAYlFyRdenX4LPXDX1GQ9/rBT5GRuCxwGM+o5woigA0bWTqOniXmnVJh8gi+QKJJ8vZC9PUHNYBOrQwRpZt2ViHBjRMSl2HuhzfDPhmKJjfFStUgaoqLQB0pCikLgvKE8TQdT1kWeQPhBAh5Pzirt7c65IXL/dySsd1sGEYWqulQYgIsSi28b8IIT+ntyTzwh14DAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/e4706/IO_Broker_Cloud_06.avif 230w", "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/d1af7/IO_Broker_Cloud_06.avif 460w", "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/b6582/IO_Broker_Cloud_06.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/a0b58/IO_Broker_Cloud_06.webp 230w", "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/bc10c/IO_Broker_Cloud_06.webp 460w", "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/87ca7/IO_Broker_Cloud_06.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/81c8e/IO_Broker_Cloud_06.png 230w", "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/08a84/IO_Broker_Cloud_06.png 460w", "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/e9beb/IO_Broker_Cloud_06.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/fcee1b57e9cdec6bbc174e78116b7ef8/e9beb/IO_Broker_Cloud_06.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-8",
        "aria-label": "iobroker cloud adapter 8 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.565217391304348%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABcElEQVQY0yXMvy/EUADA8Y7+AbvBZBSrySLBJOI2DCRGg0EiTBZikIuwCUJOB79OuJJwzl0kfqu7c/392tf2+vrec+0r9eNERCTf6TN8ubbugY2jwtTCeuckP3oYj+zWRtLR+B5cz1ZSl2Azr6fy6lQG9W6xBB/282GCD4a2w6Fd1reBuJb2nqXN9MRCanh+J1lg00f+3CmZPa4mz9yDCzV9DR91L1tyDkUkFLFQxMclclL+S3hCXENjU2vXQHPH4NjMsulRzQ1cEpl+pPuxYUBZhwElb6z2HbPv96ges3rMQooiit4CzK1tbe+f5PaE88KNCEoVTVdqYS14ISygBjBkSVJUVZKViiTLqmYYFrRdC1qaZQLocqvi18r9x+LdT0b5tHP5UvYCiCJCPiHU0HVFUSVJei4/AwAQ8gghmBDLMsH1lakZnKg5FmY4qpPgFZznpIzg3t5RSgkhENoQQs+rum7VcZx/xBj/rR9EH9q/4J4e2XWnH1cAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/90b0072e706fe241829cf7b305992597/e4706/IO_Broker_Cloud_07.avif 230w", "/en/static/90b0072e706fe241829cf7b305992597/d1af7/IO_Broker_Cloud_07.avif 460w", "/en/static/90b0072e706fe241829cf7b305992597/b6582/IO_Broker_Cloud_07.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/90b0072e706fe241829cf7b305992597/a0b58/IO_Broker_Cloud_07.webp 230w", "/en/static/90b0072e706fe241829cf7b305992597/bc10c/IO_Broker_Cloud_07.webp 460w", "/en/static/90b0072e706fe241829cf7b305992597/87ca7/IO_Broker_Cloud_07.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/90b0072e706fe241829cf7b305992597/81c8e/IO_Broker_Cloud_07.png 230w", "/en/static/90b0072e706fe241829cf7b305992597/08a84/IO_Broker_Cloud_07.png 460w", "/en/static/90b0072e706fe241829cf7b305992597/e9beb/IO_Broker_Cloud_07.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/90b0072e706fe241829cf7b305992597/e9beb/IO_Broker_Cloud_07.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-9",
        "aria-label": "iobroker cloud adapter 9 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.826086956521735%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB40lEQVQoz42QTWsTQRjH90v4FTyKn0HwppV6EnwBq4ihgkq9ePTi3RfUg5YiSVMQRIxJSVrb+prQ9ighutmX2czMzs6z2Ul2k7a7m86IG6IiWPrjd5n/8Od5ZrT7z18/W1o+ffth7o0/V41my+GdSnf+fevdtvV2y1reNvN1nCv1r5f6ucyblXCuGt0oiQt5pN17lH9SKE/ffXFlyT1X8M8v+hcX4VqRzL5yZ4ru1SK9VHCnFuDMAkxNPPsSTs3zy0WmHTl24ujJmePTtz7r4QdLVVvxupF+tPdX26NyK618H620RzU9LbeSlXa6ZqQbZlr9kdT00RdjqNVW1z7Vt+qNTfA9m5J0J4qHodyL5F6k4swkUvHg1zGJkt3I4uNwEA97muEn3q7cUQoR91vbDoIugM8nehM5+OD7mMGmzvkk0Z42eg++Ro8bkUMo6TjIcToYI4Q45/5fAECn0/EYC0X3d6hF/Z5SUkrpeR7zPM45APzTHMMYy27/JFovHEilRvvScRyEkGWZmBAhRPcQaHGaqmy0SylCyDRNgjFjjFJXCDGe8N+yypBScs5d1wUAIQTG2LZtSgnG+FBlSqnjOAAQBAEhpNlsGm0DY3zQ2moCIcSybA7cy75N13XDMIPgoDf/BNUZbZhFgNw0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/e4706/IO_Broker_Cloud_08.avif 230w", "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/d1af7/IO_Broker_Cloud_08.avif 460w", "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/b6582/IO_Broker_Cloud_08.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/a0b58/IO_Broker_Cloud_08.webp 230w", "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/bc10c/IO_Broker_Cloud_08.webp 460w", "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/87ca7/IO_Broker_Cloud_08.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/81c8e/IO_Broker_Cloud_08.png 230w", "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/08a84/IO_Broker_Cloud_08.png 460w", "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/e9beb/IO_Broker_Cloud_08.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/a0d3c14ef7a6b71cb2eb9829b8b9708a/e9beb/IO_Broker_Cloud_08.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-10",
        "aria-label": "iobroker cloud adapter 10 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.695652173913043%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABX0lEQVQY00XLTS/DYADA8X4TZ9/AJ5CQOEgckHBAOPsADoQTkRDBYq/ZksU0OLgQ2jKbScRhidVeaq12z2ufdm3XojPEhd///OdW9nOru9nhxZ0Zns6eOlO8vXBM18/khNSIio2kVN++VCdzbPzQmsjZ4zlrmrdnT+xp3hpNqFyUv0idimNLybVznL4xMg8sXWxdPqqlGipUjJJsSE8g88CS9yRaALECit/BVAnFCyAialzfwEj/0Pzg3HL5uelD/fuj0wuctkepWgsdJwj88M3veiywUNhhFtR8hjsWtpHeC9rc5l58K5aNpI+qal1WWkYLucyTaLEoHPQAQ22XmBZAGGFKTYYwIdQkJtMNgAnlHM/vfn5+f/UqL0pZ1lRNt7EtAEEE12Gna0BMTZNSgjHBfwjVX3UIIbd56y6L3kbelZvVWl1Rmr/zVUsQYf7dCzUDAEwg+od/I5qqAQB+AL0oJDAbZ1T/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/6b23439b59a63726c26a836e2c10f62f/e4706/IO_Broker_Cloud_09.avif 230w", "/en/static/6b23439b59a63726c26a836e2c10f62f/d1af7/IO_Broker_Cloud_09.avif 460w", "/en/static/6b23439b59a63726c26a836e2c10f62f/b6582/IO_Broker_Cloud_09.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/6b23439b59a63726c26a836e2c10f62f/a0b58/IO_Broker_Cloud_09.webp 230w", "/en/static/6b23439b59a63726c26a836e2c10f62f/bc10c/IO_Broker_Cloud_09.webp 460w", "/en/static/6b23439b59a63726c26a836e2c10f62f/87ca7/IO_Broker_Cloud_09.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/6b23439b59a63726c26a836e2c10f62f/81c8e/IO_Broker_Cloud_09.png 230w", "/en/static/6b23439b59a63726c26a836e2c10f62f/08a84/IO_Broker_Cloud_09.png 460w", "/en/static/6b23439b59a63726c26a836e2c10f62f/e9beb/IO_Broker_Cloud_09.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/6b23439b59a63726c26a836e2c10f62f/e9beb/IO_Broker_Cloud_09.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-11",
        "aria-label": "iobroker cloud adapter 11 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36.52173913043478%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnElEQVQY013LTUvbYADA8XwJb7t68DKYbN/Aa2En9aRsh+oczI0pghehUhsHAxFhoDLYVjeoqK21QsGXgyY1Ga62llhtaQrpmvC85UmalyZRK7rT/PO7/pmnfQPxzDG7vB6aSUxkzfdp9CGjz+1UNzkpI1Z2hKuMWJnaBeEkHkvh0RQeT5OJXTKeRsNxmenq7p3+vDo2uzw4lxzZar1OkPCGPrpB3m7i8Doe+omGf6GBNdIff/AD939Hg2vk5TcymcbMk54Xz0OvnoVGPn76+rd1m2+0yyC4BEGx6ZfUQNKCPw2/2PQlLcgrXgleS/S2oHjFZlADNnN2VpDrNa3Z0KGayLmlGnVb2KTEMv5jG8T2Hee84OztuYEDECkrhDmsWvsV66B+c66181dIUalBKdEfwxABzwNfVsCbd9B1VA0qKmKSBbJdMlIXbq5uKbXy0uJCNBqdn2f/FYvFWJaNRCLZbLZl2Sa1TWQSaiAIEQQMJbjtWJ1Ox/faUrnK5X5zvMCdnB7xp8e8wPOcIAiiKMqyjBDSIIJYh/fd73dZEUfTxGqWCwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/6b112b87228a053b82a5afbcb853d5ea/e4706/IO_Broker_Cloud_10.avif 230w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/d1af7/IO_Broker_Cloud_10.avif 460w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/b6582/IO_Broker_Cloud_10.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/6b112b87228a053b82a5afbcb853d5ea/a0b58/IO_Broker_Cloud_10.webp 230w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/bc10c/IO_Broker_Cloud_10.webp 460w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/87ca7/IO_Broker_Cloud_10.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/6b112b87228a053b82a5afbcb853d5ea/81c8e/IO_Broker_Cloud_10.png 230w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/08a84/IO_Broker_Cloud_10.png 460w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/e9beb/IO_Broker_Cloud_10.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/6b112b87228a053b82a5afbcb853d5ea/e9beb/IO_Broker_Cloud_10.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-cloud-adapter-12",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-cloud-adapter-12",
        "aria-label": "iobroker cloud adapter 12 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36.52173913043478%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnElEQVQY013LTUvbYADA8XwJb7t68DKYbN/Aa2En9aRsh+oczI0pghehUhsHAxFhoDLYVjeoqK21QsGXgyY1Ga62llhtaQrpmvC85UmalyZRK7rT/PO7/pmnfQPxzDG7vB6aSUxkzfdp9CGjz+1UNzkpI1Z2hKuMWJnaBeEkHkvh0RQeT5OJXTKeRsNxmenq7p3+vDo2uzw4lxzZar1OkPCGPrpB3m7i8Doe+omGf6GBNdIff/AD939Hg2vk5TcymcbMk54Xz0OvnoVGPn76+rd1m2+0yyC4BEGx6ZfUQNKCPw2/2PQlLcgrXgleS/S2oHjFZlADNnN2VpDrNa3Z0KGayLmlGnVb2KTEMv5jG8T2Hee84OztuYEDECkrhDmsWvsV66B+c66181dIUalBKdEfwxABzwNfVsCbd9B1VA0qKmKSBbJdMlIXbq5uKbXy0uJCNBqdn2f/FYvFWJaNRCLZbLZl2Sa1TWQSaiAIEQQMJbjtWJ1Ox/faUrnK5X5zvMCdnB7xp8e8wPOcIAiiKMqyjBDSIIJYh/fd73dZEUfTxGqWCwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/6b112b87228a053b82a5afbcb853d5ea/e4706/IO_Broker_Cloud_10.avif 230w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/d1af7/IO_Broker_Cloud_10.avif 460w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/b6582/IO_Broker_Cloud_10.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/6b112b87228a053b82a5afbcb853d5ea/a0b58/IO_Broker_Cloud_10.webp 230w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/bc10c/IO_Broker_Cloud_10.webp 460w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/87ca7/IO_Broker_Cloud_10.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/6b112b87228a053b82a5afbcb853d5ea/81c8e/IO_Broker_Cloud_10.png 230w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/08a84/IO_Broker_Cloud_10.png 460w", "/en/static/6b112b87228a053b82a5afbcb853d5ea/e9beb/IO_Broker_Cloud_10.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/6b112b87228a053b82a5afbcb853d5ea/e9beb/IO_Broker_Cloud_10.png",
            "alt": "ioBroker Cloud Adapter",
            "title": "ioBroker Cloud Adapter",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "amazon-alexa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#amazon-alexa",
        "aria-label": "amazon alexa permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amazon Alexa`}</h2>
    <h2 {...{
      "id": "iobroker-amazon-alexa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-amazon-alexa",
        "aria-label": "iobroker amazon alexa permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.565217391304344%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdtCQK//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQMUBP/aAAgBAQABBQLJIxyHwzZ//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAXEAEBAQEAAAAAAAAAAAAAAAAAMgFR/9oACAEBAAY/ApTjj//EABoQAQABBQAAAAAAAAAAAAAAAAEAESFh0fD/2gAIAQEAAT8hH2rOxiFlwJ//2gAMAwEAAgADAAAAEIwP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EJiX/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELqn/8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAMSGh0cH/2gAIAQEAAT8QGZF00ewk7dRxEpWDc//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/7576d7ff3c2fef86a91b60b9e44b394b/e4706/Amazon_Alexa_01.avif 230w", "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/d1af7/Amazon_Alexa_01.avif 460w", "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/b6582/Amazon_Alexa_01.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/7576d7ff3c2fef86a91b60b9e44b394b/a0b58/Amazon_Alexa_01.webp 230w", "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/bc10c/Amazon_Alexa_01.webp 460w", "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/87ca7/Amazon_Alexa_01.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/7576d7ff3c2fef86a91b60b9e44b394b/e83b4/Amazon_Alexa_01.jpg 230w", "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/e41a8/Amazon_Alexa_01.jpg 460w", "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/1a144/Amazon_Alexa_01.jpg 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/7576d7ff3c2fef86a91b60b9e44b394b/1a144/Amazon_Alexa_01.jpg",
            "alt": "ioBroker Amazon Alexa",
            "title": "ioBroker Amazon Alexa",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-amazon-alexa-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-amazon-alexa-1",
        "aria-label": "iobroker amazon alexa 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.47826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAADAgT/2gAMAwEAAhADEAAAAXU6NmZ0EL//xAAZEAEBAQEBAQAAAAAAAAAAAAABAwIAETL/2gAIAQEAAQUCJxeYyOxCOgU7en2Hz//EABgRAAIDAAAAAAAAAAAAAAAAAAABERIh/9oACAEDAQE/AbuBPD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8Bwsf/xAAbEAABBAMAAAAAAAAAAAAAAAAAAhEzkSEygf/aAAgBAQAGPwKNVkSrNVWYGc6f/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMSFx/9oACAEBAAE/IcVnhTVxNfDsOqhtEi4aj//aAAwDAQACAAMAAAAQny//xAAWEQEBAQAAAAAAAAAAAAAAAAABAEH/2gAIAQMBAT8QNk6Fv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QRvIhwv/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQaGx0f/aAAgBAQABPxDEXN1ioUct6I6NTBb0fZoO0ppjHo2WzieW9E//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/057deef94459fcc0aaf925cd93d4f11f/e4706/Amazon_Alexa_02.avif 230w", "/en/static/057deef94459fcc0aaf925cd93d4f11f/d1af7/Amazon_Alexa_02.avif 460w", "/en/static/057deef94459fcc0aaf925cd93d4f11f/b6582/Amazon_Alexa_02.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/057deef94459fcc0aaf925cd93d4f11f/a0b58/Amazon_Alexa_02.webp 230w", "/en/static/057deef94459fcc0aaf925cd93d4f11f/bc10c/Amazon_Alexa_02.webp 460w", "/en/static/057deef94459fcc0aaf925cd93d4f11f/87ca7/Amazon_Alexa_02.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/057deef94459fcc0aaf925cd93d4f11f/e83b4/Amazon_Alexa_02.jpg 230w", "/en/static/057deef94459fcc0aaf925cd93d4f11f/e41a8/Amazon_Alexa_02.jpg 460w", "/en/static/057deef94459fcc0aaf925cd93d4f11f/1a144/Amazon_Alexa_02.jpg 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/057deef94459fcc0aaf925cd93d4f11f/1a144/Amazon_Alexa_02.jpg",
            "alt": "ioBroker Amazon Alexa",
            "title": "ioBroker Amazon Alexa",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-amazon-alexa-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-amazon-alexa-2",
        "aria-label": "iobroker amazon alexa 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.17391304347826%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAF3TNQc+X//xAAZEAACAwEAAAAAAAAAAAAAAAAAAwEEFCD/2gAIAQEAAQUCiqkyIMaeP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAChASAyIf/aAAgBAQAGPwLsMyzLp//EABsQAAEEAwAAAAAAAAAAAAAAAAABICFRYZHw/9oACAEBAAE/IZ1o6KMvZn//2gAMAwEAAgADAAAAEGcP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EAB4QAQACAQQDAAAAAAAAAAAAAAEAESEQQVHwMYHB/9oACAEBAAE/EFFgyodnplz5r23nQvsMLXMt50//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/4f440e0f8195e38cb9e063b83f709a74/e4706/Amazon_Alexa_03.avif 230w", "/en/static/4f440e0f8195e38cb9e063b83f709a74/d1af7/Amazon_Alexa_03.avif 460w", "/en/static/4f440e0f8195e38cb9e063b83f709a74/b6582/Amazon_Alexa_03.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/4f440e0f8195e38cb9e063b83f709a74/a0b58/Amazon_Alexa_03.webp 230w", "/en/static/4f440e0f8195e38cb9e063b83f709a74/bc10c/Amazon_Alexa_03.webp 460w", "/en/static/4f440e0f8195e38cb9e063b83f709a74/87ca7/Amazon_Alexa_03.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/4f440e0f8195e38cb9e063b83f709a74/e83b4/Amazon_Alexa_03.jpg 230w", "/en/static/4f440e0f8195e38cb9e063b83f709a74/e41a8/Amazon_Alexa_03.jpg 460w", "/en/static/4f440e0f8195e38cb9e063b83f709a74/1a144/Amazon_Alexa_03.jpg 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/4f440e0f8195e38cb9e063b83f709a74/1a144/Amazon_Alexa_03.jpg",
            "alt": "ioBroker Amazon Alexa",
            "title": "ioBroker Amazon Alexa",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "ifttt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ifttt",
        "aria-label": "ifttt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT`}</h2>
    <h2 {...{
      "id": "iobroker-ifttt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-ifttt",
        "aria-label": "iobroker ifttt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.695652173913043%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABY0lEQVQY00XMz0vCUADA8f0b/QedO3euQ9ApLLAIof6BTgURHeoWRBFFkGZaiagxPBRIunKpBNLBypj5Y3PPvff2pptuc02XBhb0uXxvX2r7JLzvp2fWjpajTU/cdMf0VVrdu+UCLH+e5oNPtUMGzEfarkhnIaq7IvrSje6hDXesM+sTqYNgPECn5jZ9O3fwOg1CeeUqB+5fhByH2XeQLYLUqxTKNwPPspcFvkzjIitd5qA/A7yPAjU2MTU+vTK5uFHgqiZuDG1jYOmqQQgoO6ZhWd3+V7entyyNOF1Nw8BqK6YqGwoa2jp17A2ehWh/mC5WuLcSEAFskw5DMpnEqQOURktDsiJBBBGWiQJHQTKp1wGWCaWZds8ZDIffnFApfNSqvNjCKiMxDxLbM/qihIjSVAjBI/IfIgp1hBC1y7TXE52tpFbkS5/lSo0HKlaTjdQDZG2jX5cQxASif78TgechQj83gySK9A+cawAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/68060388f63140fdaf5b3248868164dd/e4706/IFTTT_01.avif 230w", "/en/static/68060388f63140fdaf5b3248868164dd/d1af7/IFTTT_01.avif 460w", "/en/static/68060388f63140fdaf5b3248868164dd/b6582/IFTTT_01.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/68060388f63140fdaf5b3248868164dd/a0b58/IFTTT_01.webp 230w", "/en/static/68060388f63140fdaf5b3248868164dd/bc10c/IFTTT_01.webp 460w", "/en/static/68060388f63140fdaf5b3248868164dd/87ca7/IFTTT_01.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/68060388f63140fdaf5b3248868164dd/81c8e/IFTTT_01.png 230w", "/en/static/68060388f63140fdaf5b3248868164dd/08a84/IFTTT_01.png 460w", "/en/static/68060388f63140fdaf5b3248868164dd/e9beb/IFTTT_01.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/68060388f63140fdaf5b3248868164dd/e9beb/IFTTT_01.png",
            "alt": "ioBroker IFTTT",
            "title": "ioBroker IFTTT",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-ifttt-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-ifttt-1",
        "aria-label": "iobroker ifttt 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "25.65217391304348%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABL0lEQVQY0zXEv0sCYRgA4PtPgvamhnCL5mixBgkEQw2xorHBWlqC9hLaslDM8DqHhIaU0rQGS0tPyPNA0b773ve78+67wV8ZFsHDIywsuyOprHf/xBUu7abtgKTvpdqXmZpYVG6Kn9F8MyihL8n84pRPZAERA2nbf2u7L1Rhdn7p6CzhDp2uh199SdsTZ944bF+TYIJsRIknpq1F0Plv9RxWYvbBcTZ0eLcp9YSZOceia8fh3JIeyh0+kUlf1ccyHVe6o6o2rmmjSncgk2FdG753B7WvfhknarHaKnzUcSh02i1GycA2uIEWoyaCxcBk1GTAdeA6tXQwDbR+8R7jBiK3mM1NHYSrNzNe5lH5W34s0cw9zRfgz9N0zOXJ80ujqSqNpqIoRKOIDAEQABB/ALik55rHT6oZAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/53cb6d1c0dd2fd47d84797c194badb8c/e4706/IFTTT_02.avif 230w", "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/d1af7/IFTTT_02.avif 460w", "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/b6582/IFTTT_02.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/53cb6d1c0dd2fd47d84797c194badb8c/a0b58/IFTTT_02.webp 230w", "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/bc10c/IFTTT_02.webp 460w", "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/87ca7/IFTTT_02.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/53cb6d1c0dd2fd47d84797c194badb8c/81c8e/IFTTT_02.png 230w", "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/08a84/IFTTT_02.png 460w", "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/e9beb/IFTTT_02.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/53cb6d1c0dd2fd47d84797c194badb8c/e9beb/IFTTT_02.png",
            "alt": "ioBroker IFTTT",
            "title": "ioBroker IFTTT",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-ifttt-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-ifttt-2",
        "aria-label": "iobroker ifttt 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.695652173913043%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABU0lEQVQY012NzUoCURzF51F6kZ4goaK11K4mCsoWtREi3IRELiJT+yQLSlyYFIQtWlSaRejMXB1n1EWUGqPD6Kjzcefef0zuOhzOb3MOh2FZNnZ4xAvCay7LcRwqi0Kp/M8cKnGoJKASKlfkqhzajbLzK+trq0yhUGg0vlVtoGi0o1mWaVpumIZLV7ZtAzgABGOsqJ0WknbO0caJlLvnmWg0onR6tC869SBu3oArCiNQAgCWadzy7Ys3pa1qAHSgav7r5vKlEtpLMB7PhIBEo/spZRZp+2E0O31X47zT61sOoY6Ns1Izma91+0MAKDYMbxqP7ZOZQJJJpdy3mlx/zqO/oZWpGt6FwPRSZPx40NIpBaDYgmGPApg2OfvQ777Al5BzxQrj9gkBSrFDHEJ0DJMxyT83O+VL+/MAaksLh9XgtnIQBoAX8WfrUb+qwmb8iWLjF2Y5K4JytQJ6AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/f6ae87765c90d8de0ebd4e893471820a/e4706/IFTTT_03.avif 230w", "/en/static/f6ae87765c90d8de0ebd4e893471820a/d1af7/IFTTT_03.avif 460w", "/en/static/f6ae87765c90d8de0ebd4e893471820a/b6582/IFTTT_03.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/f6ae87765c90d8de0ebd4e893471820a/a0b58/IFTTT_03.webp 230w", "/en/static/f6ae87765c90d8de0ebd4e893471820a/bc10c/IFTTT_03.webp 460w", "/en/static/f6ae87765c90d8de0ebd4e893471820a/87ca7/IFTTT_03.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/f6ae87765c90d8de0ebd4e893471820a/81c8e/IFTTT_03.png 230w", "/en/static/f6ae87765c90d8de0ebd4e893471820a/08a84/IFTTT_03.png 460w", "/en/static/f6ae87765c90d8de0ebd4e893471820a/e9beb/IFTTT_03.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/f6ae87765c90d8de0ebd4e893471820a/e9beb/IFTTT_03.png",
            "alt": "ioBroker IFTTT",
            "title": "ioBroker IFTTT",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-ifttt-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-ifttt-3",
        "aria-label": "iobroker ifttt 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.43478260869565%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABg0lEQVQY02NwjqvwTq2tXXDYt/+Se9dZr+5zXj3nJu+4t/7k4xVHH6069mjN8Udrjj9cdeLJquOP1554vPLwvaDcyc7xtfm17QxuS/8GrvqjPf2n0ayfNvNBpM70n6azfqhM+qE3/Yfa5B+SPT8k+n4qt72V6/wo2fdToueHxtRvhrN+Fa66xaAz46/urL8qU/+oTfujNeOP+rQ/qlP/aE7/ozIVxFaZ+kdx4i/x2f+7Y6dkFWwTm/1fdfIvvZm/Vaf9L1h+jUFu4m+5ib/lJ/2WhzMmIUQUJoGQ5sy/njPeus76oDbtr+6MXw6LfqlO+5+z9AoDRBoNyU/6LTvhd/OhP9bzf0v0/Vac9Ftq0l/piX8hUnITfklN/Je86AYWzUqTQRoSNv5Zde1v+5G/ylNAgopgBFGgPOmXeP/fsrV3sGuW7PvtuuRP8Oo/fiv+SE/4LTMBoROkAKy5ZPVtdM2Kk35L9/9O2fynePffjK1/otf/yd3+R3cGyBeKqJpL19wGAIU7GUqUZIsNAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/00b725a3b8c80d57ff0d32db5fffc553/e4706/IFTTT_04.avif 230w", "/en/static/00b725a3b8c80d57ff0d32db5fffc553/d1af7/IFTTT_04.avif 460w", "/en/static/00b725a3b8c80d57ff0d32db5fffc553/b6582/IFTTT_04.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/00b725a3b8c80d57ff0d32db5fffc553/a0b58/IFTTT_04.webp 230w", "/en/static/00b725a3b8c80d57ff0d32db5fffc553/bc10c/IFTTT_04.webp 460w", "/en/static/00b725a3b8c80d57ff0d32db5fffc553/87ca7/IFTTT_04.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/00b725a3b8c80d57ff0d32db5fffc553/81c8e/IFTTT_04.png 230w", "/en/static/00b725a3b8c80d57ff0d32db5fffc553/08a84/IFTTT_04.png 460w", "/en/static/00b725a3b8c80d57ff0d32db5fffc553/e9beb/IFTTT_04.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/00b725a3b8c80d57ff0d32db5fffc553/e9beb/IFTTT_04.png",
            "alt": "ioBroker IFTTT",
            "title": "ioBroker IFTTT",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-ifttt-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-ifttt-4",
        "aria-label": "iobroker ifttt 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.86956521739131%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB00lEQVQoz5WTy47TMBSG8yDV7FDeZFj1CZB4Bd6DPeIhWCB2rWa2VF3Ajoug6rSdJI7vl5ykid04hjitVDQIiU+WvDm/z39+24kfQgjh9cq/WvrwnySn3ofg333zbz+P4mEYD2vbFgDqJwBA13UhDH3fe+8To3VVVQQ9FoeH7XZ72O83m818Pp/NZmmaPrsiTdObm5vnt7f7/R6g1lonUwfBOcFYCmmMqapqvV4vFou7+/u7P1kul6vVR601RBLvR7dSyrwoCCFSyizPorfzCE8ZLiRTBWdst9sdDgeE0O5hBwAhBO/9cIWPXJ94FmutGaOCC0IpwZiMUM5FXJxFtNZN0wCAtXbqfxYrpQihUUKVUl3XtW3bXWE765w7RaZJx87TBgCUEiGElFIpZYyZ7sYYA/WYKNRwPB6bpqnr2jo3Tto2585SiLwoEEJ5lmV5jgpUlmWe55RSxhjG2BjDOaeMUkrbGOd3SS9iqR6zvMQ4VtMyIqScHkbTNNdp+yj5qXkSQnxStakEqQRRYoyHEFIixBirAIwxWhsZUVLCxfYPxZIpeQz9F2y/Yscre7Kdc85a1/4N51wfAxvFfUzuzSf/8oN/8d4vt0M09q//MNn+Lf4Fq+UA4s0qF1oAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/b7aed1e4097b78ddd0b571962171d3df/e4706/IFTTT_05.avif 230w", "/en/static/b7aed1e4097b78ddd0b571962171d3df/d1af7/IFTTT_05.avif 460w", "/en/static/b7aed1e4097b78ddd0b571962171d3df/b6582/IFTTT_05.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/b7aed1e4097b78ddd0b571962171d3df/a0b58/IFTTT_05.webp 230w", "/en/static/b7aed1e4097b78ddd0b571962171d3df/bc10c/IFTTT_05.webp 460w", "/en/static/b7aed1e4097b78ddd0b571962171d3df/87ca7/IFTTT_05.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/b7aed1e4097b78ddd0b571962171d3df/81c8e/IFTTT_05.png 230w", "/en/static/b7aed1e4097b78ddd0b571962171d3df/08a84/IFTTT_05.png 460w", "/en/static/b7aed1e4097b78ddd0b571962171d3df/e9beb/IFTTT_05.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/b7aed1e4097b78ddd0b571962171d3df/e9beb/IFTTT_05.png",
            "alt": "ioBroker IFTTT",
            "title": "ioBroker IFTTT",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <h2 {...{
      "id": "iobroker-ifttt-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-ifttt-5",
        "aria-label": "iobroker ifttt 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.78260869565218%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACC0lEQVQoz02OTW8SQRjH9wP4BbybmHjRsx/AmB68tBo1HppolDS2Bw4aPXvoxejNxItR2hKXKlBEsNFSLLYR5UIstREW9n12Zpa32dcBdioUCL/8M3nyzzN5ftzFKzffJHefvohce7YV3naXP7VXMtZqRtj6+e9zqZ4pCZlS/XG2dX+rs5TqhFKd5XQ3nO2upDt31iXu/OW513z2ycuNhdX0g0R38T26GzNDvPYooYY/aku8+nBTu7UO5yPo+hpaiKAba+h2FM9HUCgGuDNnz12aW7xw9V74eXRPYqmKt1Ol2eNestJLjMKXafKQpv/SxB8/deSnjuhm2efLtFCzudiH+Hbu+7d84bBSYcwPAspYjwU08K3AJ4xazCeBR8YvJQOfUIf0POLZba4CnGPDkywGLVos/srt5vcKhf2DA03XsWkihCFCsqJIsiLLsqJpCA9Bo3DvfrfflsiropOr2vmdr9GNKM/z8XhcluXh0ghJkkRRbIgNWVGmJcaYU4FBLIsx5jpOq9slrksIcWy72WyaE5ozmDNwHWK5nscYc/p9/cc+yH7Rm6ZhGGjihvDQHGIEMYYz5fByvz/oDwbDy56niaIm1HWENAhVw9AgPA3AaDqfRoXQQIhjE2zbrtWFal0QalWhJgAAxnIYiw0RGrDVas06o9nPrusCAIwxEM2gjtB1HQAwLf9rnwBIiC5QeUHycwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/b0a90865f3cc055d8b547af6a3c145af/e4706/IFTTT_06.avif 230w", "/en/static/b0a90865f3cc055d8b547af6a3c145af/d1af7/IFTTT_06.avif 460w", "/en/static/b0a90865f3cc055d8b547af6a3c145af/b6582/IFTTT_06.avif 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/b0a90865f3cc055d8b547af6a3c145af/a0b58/IFTTT_06.webp 230w", "/en/static/b0a90865f3cc055d8b547af6a3c145af/bc10c/IFTTT_06.webp 460w", "/en/static/b0a90865f3cc055d8b547af6a3c145af/87ca7/IFTTT_06.webp 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/b0a90865f3cc055d8b547af6a3c145af/81c8e/IFTTT_06.png 230w", "/en/static/b0a90865f3cc055d8b547af6a3c145af/08a84/IFTTT_06.png 460w", "/en/static/b0a90865f3cc055d8b547af6a3c145af/e9beb/IFTTT_06.png 730w"],
            "sizes": "(max-width: 730px) 100vw, 730px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/b0a90865f3cc055d8b547af6a3c145af/e9beb/IFTTT_06.png",
            "alt": "ioBroker IFTTT",
            "title": "ioBroker IFTTT",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      